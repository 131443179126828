@import url('https://marketing-assets.vercel.app/Brice.css');
@import url('https://marketing-assets.vercel.app/Beausite.css');

html,
body {
  font-family: 'Beausite', sans-serif !important;
  font-weight: 400;
  overflow: hidden;
  height: 100%;
  overscroll-behavior: none; /* disable pull-to-refresh */
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

body.redirect {
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)); */
  background-repeat: repeat;
}

* {
  font-family: 'Beausite', sans-serif !important;
}

.pin-validation {
  font-family: 'Brice', sans-serif !important;
  font-weight: 900;
  color: #1C1C1C;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.5rem
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #E0E0E0 !important;
}

input:autofill,
input:-webkit-autofill-strong-password,
input:-webkit-autofill-strong-password-viewable,
input:-webkit-autofill-and-obscured {
  color: inherit !important;
  border-color: #E0E0E0 !important;
}

#just-form-layout {
  --transition-speed: 0.4s;
  --effect: ease-in-out;
  position: fixed;
  transition: transform var(--transition-speed) var(--effect);
}

#__next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

/* mobile and very small screens portrait and landscape mode */
@media screen and (max-width: 750px), screen and (max-height:450px) {
  #just-form-layout {
    width: 100%;
    height: 100%;
    z-index: 2147483645;
    &.inIframe {
      transform: translateY(100%);
      &.slideIn {
        transform: translateY(0%) !important;
      }
    }
  }
}

/* Desktop medium */
@media screen and (min-width: 750px) and (min-height: 450px) {
  #just-form-layout {
    width: 763px;
    height: 706px;
    border-radius: 30px;
    overflow: hidden;
  }
}

/* Desktop fullsize */
@media screen and (min-width: 900px) and (min-height: 450px) {
  #just-form-layout {
    width: 909px;
    height: 706px;
    border-radius: 30px;
    overflow: hidden;
  }
}

#chakra-toast-manager-top {
  z-index: 99999999999 !important;
}